








import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Areas extends Vue {
  get areas(): string[] {
    return ['Projektledning', 'Projekteringsledning', 'Byggledning', 'Byggkontroll'];
  }

  getSource(area: string): string {
    return require(`../assets/images/${area.toLowerCase()}.png`);
  }
}
