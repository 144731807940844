










import { Component, Vue } from 'vue-property-decorator';
import Banner from '@/components/Banner.vue';
import Home from '@/views/Home.vue';
import Areas from '@/views/Areas.vue';
import History from '@/views/History.vue';
import Contact from '@/views/Contact.vue';

@Component({
  components: {
    Banner,
    Home,
    Areas,
    History,
    Contact,
  },
})
export default class App extends Vue {}
