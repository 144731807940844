






import { Component, Vue } from 'vue-property-decorator';
import Topbar from '@/components/Topbar.vue';

@Component({
  components: {
    Topbar,
  },
})
export default class Banner extends Vue {}
